import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// pages
import MainPage from './pages/main-page/MainPage'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <div>
            <Route path="/" exact component={MainPage} />
          </div>
      </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
