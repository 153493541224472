import React, { Component, Fragment } from 'react';

import fonts from '../../styles/fonts.css'
import grid from '../../styles/grid.css'
import styles from './MainPage.css'

class MainPage extends Component {
    render() {
        return (
            <Fragment>
                <div className={grid.container}>
                    <div className={styles.navbar}>
                        <div className={grid.row}>
                            <div className={grid.column}>
                                <div className={[grid.row, grid['mobile-row'], styles['centered-horizontal-container']].join(' ')}>
                                    <div className={styles["navbar-photo"]} />
                                    <div className={[styles["navbar-title"], styles["centered-vertically"]].join(' ')}>
                                        <div className={fonts["title-bold"]}>
                                            Zoe Trapeznikova
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            // <Fragment>
            //     <NavigatoinBar />
            // </Fragment>
        )
    }
}

export default MainPage;